import React from "react";
import { Link } from "gatsby";
import { Container, Row, Col } from "react-bootstrap";

import { Section, Button, Title, Text, Box } from "../components/Core";
import PageWrapper from "../components/PageWrapper";
import Contact from "../sections/common/Contact";
import { device } from "../utils";

import "../assets/fonts/icon-font/css/style.css";

const WorkSingle = () => {
  return (
    <>
      <PageWrapper darkTheme>
        <Section className="mt-lg-5">
          <Container>
            <Row>
              <Col lg="8">
                <Text variant="tag">user design</Text>
                <Title variant="secSm" className="my-4">
                  Australia Bushfires Infographic{" "}
                </Title>
                <Text
                  variant="p"
                  css={`
                    max-width: 750px;
                  `}
                >
                  Created and designed an infographic on the 2019-2020 Australian bushfire season. Followed an iterative design process and developed
                  fluency in expressing information visually as diagrams, charts, maps, and icons. Gained a deep understanding of the nature of
                  visual potential, visual design principles, and the impact of color and typography.
                </Text>
              </Col>
            </Row>
          </Container>
        </Section>
        <div className="mt-lg-3">
          <Container>
            <Row>
              <Col lg="4" className="mb-4 mb-lg-0">
                <Text variant="tag">Role</Text>
                <Title variant="cardBig" className="mt-3">
                  Visual Designer
                </Title>
              </Col>
              <Col lg="4" className="mb-4 mb-lg-0">
                <Text variant="tag">Date</Text>
                <Title variant="cardBig" className="mt-3">
                  Jan 2020 - Mar 2020
                </Title>
              </Col>
              <Col lg="4">
                <Link
                  to="https://docs.google.com/presentation/d/1QAuKiD813I8H_LQYRR2cg_zYnvQ0pgmVPYijSB6YIKY/edit?usp=sharing" target="_blank"
                  spy={true}
                  smooth={true}
                  offset={-50}
                  duration={1000}
                >
                  <Button arrowRight>Design Process</Button>
                </Link>
              </Col>
            </Row>
          </Container>
        </div>
        <Section className="mt-lg-5">
          <Container>
            <Row>
              <Col xs="11" className="mb-2">
                <a href="../../Australia_Bushfires_Infographic.pdf" target="_blank">
                  <img src={"https://res.cloudinary.com/ryandaaang/image/upload/v1616819500/Australian%20Bushfire%20Infographic/Australia_Bushfires_Infographic.png"} alt="australian bushfires infographic" className="img-fluid w-100" />
                </a>
              </Col>
            </Row>
            <Text variant="p" style={{marginTop: 30}}>Design Tools: Adobe Illustrator, Adobe Photoshop, Tableau</Text>
          </Container>
        </Section>
        <Section bg="dark">
          <Container>
            <div className="text-center">
              <Text variant="tag" className="mb-1" color="lightShade">
                Next Project
              </Text>
              <Link to="/carbon-emissions">
                <Button
                  arrowRight
                  className="border-0 bg-transparent shadow-none text-capitalize py-3"
                  css={`
                    font-weight: 700
                    font-size: 1.5rem;
                    letter-spacing: -1.2px;
                    line-height: 1.375;
                    @media ${device.md} {
                      font-size: 2rem;
                    }
                  `}
                >
                  Carbon Emissions
                </Button>
              </Link>
            </div>
          </Container>
        </Section>
        <Box py={4}>
          <Contact />
        </Box>
      </PageWrapper>
    </>
  );
};
export default WorkSingle;
